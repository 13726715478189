import './App.css';
import router from './routes';
import { RouterProvider } from 'react-router-dom';
import { createContext, useEffect, useState } from 'react';
import Login from './Pages/Login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const appContext = createContext()

function App() {
  const [token, setToken] = useState(null)
  const [confirm, setConfirm] = useState({})
  const [audioPlay, setAudioPlay] = useState({})

  useEffect(() => {
    let t = localStorage.getItem('feel_token')
    if(t) setToken(t)
  }, [])

  return (
    <appContext.Provider value={{token, setToken, confirm, setConfirm, audioPlay, setAudioPlay}}>
      <ToastContainer />
      {token ? <RouterProvider router={router} /> : <Login />}
    </appContext.Provider>
  );
}

export default App;
