import React, {useState, useEffect} from "react";
import { Link, useLocation, useNavigate, useNavigation } from "react-router-dom";
import { AudioFile,  Delete, Edit, PlayCircle } from "@mui/icons-material";
import { path } from "../constants";
import axios from 'axios'
import Loader from "../Components/Loader";
import { useContext } from "react";
import { appContext } from "../App";
import { toast } from "react-toastify";

export default function Musics(){
    const [audios, setAudios] = useState([])
    const [loading, setLoading] = useState(true)
    const {setConfirm, setAudioPlay} = useContext(appContext)
    const navigation = useNavigate()

    const fetchAudios = () => {
        axios(path.audio).then(r => {
            setAudios(r.data.audios)
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchAudios()
    }, [])

    const navToEdit = (a) => {
        navigation('/add-audio', {
            state: a
        })
    }

    const deleteAudio = (id) => {
        return function(){
            return axios.delete(path.audio, {
                data: {id}
            }).then(r => {
                setAudios(m => m.filter(i => i.id != id))
                toast.success('audio deleted')
            })
        }
    }

    return (
        <div className={'flex flex-col overflow-auto flex-1 p-6 gap-6 '}>
            <Link to="/add-audio" className='self-end bg-main p-4 rounded-md font-bold flex items-center gap-2'>
                <AudioFile>Filled</AudioFile>
                Add Audio
            </Link>
            <p className='text-[16px] uppercase font-bold'>Audios</p>
            <div className={'flex-1 rounded-md ' + (!loading ? 'overflow-auto' : 'hidden')}>
            <table>
                <thead>
                    <tr>
                        <th>
                            File
                        </th>
                        <th>
                            Title
                        </th>
                        <th>
                            Description
                        </th>
                        <th>
                            Tune
                        </th>
                        <th>
                            Format
                        </th>
                        <th>
                            Length
                        </th>
                        <th>
                            Length (min.)
                        </th>
                        <th>
                            Mood
                        </th>
                        <th>
                            Mood Description
                        </th>
                        <th>
                            Mood  HZ Range
                        </th>
                        <th>
                            Intensity
                        </th>
                        <th>
                            Intesity desc
                        </th>
                        <th>
                            Date Created
                        </th>
                        <th>
                            Date Updated
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {audios.map(audio => (
                        <tr>
                            <td>
                                <button onClick={() => {
                                    setAudioPlay({
                                        visible: true,
                                        title: audio.title,
                                        streamId: audio.id
                                    })
                                }} className='bg-none border-none flex justify-center items-center relative'>
                                    <PlayCircle className='animate-ping'>Filled</PlayCircle>
                                    <PlayCircle className='absolute'>Filled</PlayCircle>
                                </button>
                            </td>
                            <td>{audio.title}</td>
                            <td>{audio.description}</td>
                            <td>{audio.tune}</td>
                            <td>{audio.format}</td>
                            <td>{audio.length}</td>
                            <td>{audio.duration?.split('.')?.at(0)} seconds</td>
                            <td>{audio.mood?.name}</td>
                            <td>{audio.mood?.description}</td>
                            <td>{audio.mood?.hz_range}</td>
                            <td>{audio.intensity}</td>
                            <td>{audio.intensity_desc}</td>
                            <td>{audio.createdAt.split('.')[0]}</td>
                            <td>{audio.updatedAt.split('.')[0]}</td>
                            <td className='flex items-center gap-3'>
                                <button onClick={() => navToEdit(audio)} className='border-none bg-none'>
                                    <Edit>Filled</Edit>
                                </button>
                            
                                <button onClick={() => setConfirm({
                                    visible: true,
                                    action: deleteAudio(audio.id)
                                    }) }  className='border-none bg-none'>
                                    <Delete style={{color: 'red'}}>Filled</Delete>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {!audios.length && <h4 className='text-center mt-4'>No Data.</h4>}
            </div>
            <Loader loading={loading} />
        </div>
    )
}