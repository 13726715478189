import { Link, useNavigate } from "react-router-dom";
import { Mood, Delete, Edit } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { baseUrl, path } from "../constants";
import { appContext } from "../App";
import { toast } from "react-toastify";
import Loader from "../Components/Loader";


export default function Users(){   
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const {setConfirm} = useContext(appContext)
    const navigation = useNavigate()
    

    // useEffect(() => {
    //     fetchMoods().then(d => { setMoods(d); setLoading(false) })
    // }, [])

    // const navToEdit = (m) => {
    //     navigation('/add-mood', {
    //         state: m
    //     })
    // }

    // const deleteMood = (id) => {
    //     return function(){
    //         return axios.delete(path.mood, {
    //             data: {id}
    //         }).then(r => {
    //             setMoods(m => m.filter(i => i.id != id))
    //             toast.success('mood deleted')
                

    //         })
    //     }
    // }

    return (
        <div className='flex flex-col flex-1 overflow-auto p-6 gap-6'>
            {/* <Link to="/add-mood" className='self-end bg-main p-4 rounded-md font-bold flex items-center gap-2'>
                <Mood>Filled</Mood>
                Add Mood
            </Link> */}
            <p className='text-[16px] uppercase font-bold'>App Users</p>
            <div className='overflow-y-auto flex-1 rounded-md '>
            <table className='w-full'>
                <thead>
                    <tr>
                        <th>
                            First Name
                        </th>
                        <th>
                            Last Name
                        </th>
                        <th>
                            Email
                        </th>
                        <th>
                            Date Created
                        </th>
                        <th>
                            Date Updated
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {/* {moods.map(m => (
                        <tr>
                            <td>{m.name}</td>
                            <td>{m.description}</td>
                            <td>{m.hz_range}</td>
                            <td>{m.createdAt?.split('.')[0]}</td>
                            <td>{m.updatedAt?.split('.')[0]}</td>
                        <td className='flex items-center gap-3'>
                                <button onClick={() => navToEdit(m)} className='border-none bg-none'>
                                    <Edit>Filled</Edit>
                                </button>
                                <button onClick={() => setConfirm({
                                    visible: true,
                                    action: deleteMood(m.id)
                                    }) } className='border-none bg-none'>
                                    <Delete style={{color: 'red'}}>Filled</Delete>
                                </button>
                            </td>
                        </tr>
                    ))} */}
                </tbody>
            </table>
            {!users.length && <h4 className='text-center mt-4'>No Data.</h4>}
            </div>
            <Loader loading={loading} />
        </div>
    )
}