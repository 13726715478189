import Footer from "./Components/Footer";
import Header from "./Components/Header";
import { Outlet } from "react-router";
import SideBar from "./Components/SideBar";
import Confirm from "./Components/Confirm";
import AudioPlayer from "./Components/AudioPlayer";

export default function Layout(){
    return (
        <div className='h-screen flex flex-col'>
            <Header />
            <div className='flex flex-1'>
                <SideBar />
                <div className='flex-1 flex flex-col overflow-y-auto'>
                    <Outlet />
                    <Footer />
                </div>
            </div>
            <Confirm />
            <AudioPlayer />
        </div>
    )
}