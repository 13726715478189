import CastIcon from '@mui/icons-material/Cast';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import GroupIcon from '@mui/icons-material/Group';

export default function Dashboard(){
    return (
        <div className='flex-1 grid grid-cols-2 p-10 gap-10'>
            <div className='flex flex-col p-6 rounded-lg shadow-lg bg-white items-center justify-center'>
                <h2 className='text-[65px] font-bold'>0</h2>
                <div className='flex items-center gap-4'>
                    <CastIcon>Filled</CastIcon>
                    <p className='text-[34px]'>Streams Today</p>
                </div>
            </div>
            <div className='flex flex-col p-6 rounded-lg shadow-lg bg-white items-center justify-center'>
                <h2 className='text-[65px] font-bold'>0</h2>
                <div className='flex items-center gap-4'>
                    <CastConnectedIcon>Filled</CastConnectedIcon>
                    <p className='text-[34px]'>Streams This Week</p>
                </div>
            </div>
            <div className='flex flex-col p-6 rounded-lg shadow-lg bg-white items-center justify-center'>
                <h2 className='text-[65px] font-bold'>0</h2>
                <div className='flex items-center gap-4'>
                    <GroupIcon>Filled</GroupIcon>
                    <p className='text-[34px]'>Active Users (Today)</p>
                </div>
            </div>
            <div className='flex flex-col p-6 rounded-lg shadow-lg bg-white items-center justify-center'>
                <h2 className='text-[65px] font-bold'>0</h2>
                <div className='flex items-center gap-4'>
                    <GroupIcon>Filled</GroupIcon>
                    <p className='text-[34px]'>Active Users (This Week)</p>
                </div>
            </div>
        </div>
    )
}