import axios from 'axios'
import { useContext, useState } from 'react'
import { appContext } from '../App'
import logo from '../assets/logo.png'
import { path } from '../constants'

export default function Login(){
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const {setToken} = useContext(appContext)

    const login = (e) => {
        e.preventDefault()
        setLoading(true)
        axios.post(path.login, {
            email: e.target.email.value,
            password: e.target.password.value
        }).then(response => {
            let data = response.data
            if(data.success){
                setToken(data.token)
                localStorage.setItem('feel_token', data.token)
            } else setError('Incorrect email or password')
            
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className='h-screen bg-main flex items-center justify-center'>
            <form onSubmit={login} className='p-4 rounded-md bg-white flex flex-col gap-4 shadow-md'>
                <img src={logo} alt='logo' className='w-[90px] object-contain self-center' />
                <input type='text' placeholder='Email' name='email' className='border-[1px] border-main px-4 py-2 rounded-sm w-[350px] outline-none' required/>
                <input type='password' placeholder='Password' name='password' className='border-[1px] border-main px-4 py-2 rounded-sm w-[350px] outline-none' required/>
                <button className='bg-main p-2 text-[18px] font-bold text-white' type="submit" disabled={loading}>{loading ? 'Trying...' : 'Login'}</button>
                {error && <p className='p-4 bg-[#ff000040] text-[#ff0000] font-bold text-sm m-4 rounded-sm text-center'>{error}</p>}
                <p className='text-sm text-main self-center'>Copyright &#169; 2024 Feel App all rights reserved.</p>
            </form>
        </div>
    )
}