export const tuneOptions = [
    {value: 'C', label: 'C'},
    {value: 'C#', label: 'C#'},
    {value: 'D', label: 'D#'},
    {value: 'E', label: 'E'},
    {value: 'F', label: 'F'},
    {value: 'F#', label: 'F#'},
    {value: 'G', label: 'G'},
    {value: 'G#', label: 'G#'},
    {value: 'A', label: 'A'},
    {value: 'A#', label: 'A#'},
    {value: 'B', label: 'B'},
]

export const lengthOptions = [
    {value: 'Quick', label: 'Quick'},
    {value: 'Short', label: 'Short'},
    {value: 'Mid', label: 'Mid'},
    {value: 'Long', label: 'Long'},
    {value: 'AllNight', label: 'AllNight'},

]
export const moodOptions = [
    {value: 'Sleep', label: 'Sleep'},
    {value: 'Rest', label: 'Rest'},
    {value: 'Heal', label: 'Heal'},
    {value: 'Activate', label: 'Activate'},
    {value: 'Power', label: 'Power'},
]

export const moodHZRangeOptions = [
    {value: '4.5-8.1', label: '4.5 - 8.1'},
    {value: '6.3-9', label: '6.3 - 9'},
    {value: '9', label: '9'},
    {value: '8.1-11', label: '8.1 - 11'},
    {value: '7', label: '7'},
]

export const intensityOptions = [
    {value: 'Calm', label: 'Calm'},
    {value: 'Balanced', label: 'Balanced'},
    {value: 'Intense', label: 'Intense'}
]

export const intensityDescOptions = [
    {label: 'x/2x', value: 'x/2x'},
    {label: 'x/x', value: 'x/x'},
    {label: '2x/x', value: '2x/x'}
]