import logo from '../assets/logo.png'

export default function Loader({loading}){
    if(!loading) return null
    return (
        <div className='flex items-center flex-col flex-1'>
            <img src={logo} className='w-[100px] object-contain animate-ping' />
            <p className='mt-[50px]'>Loading...</p>
        </div>
    )
}