import { useContext } from "react"
import { appContext } from "../App"

export default function Confirm(){
    const {setConfirm, confirm} = useContext(appContext)
    const handleAction = () => {
        confirm.action().then(_ => setConfirm({}))
    }
    if(!confirm.visible) return null
    return (
        <div className='dim'>
            <div className='alert-box'>
                <h3>Are you sure?</h3>
                <p>{confirm.message}</p>
                <div className="btns">
                    <button onClick={handleAction}>Yes</button>
                    <button onClick={() => setConfirm({})}>No</button>
                </div>
            </div>
        </div>
    )
}