import {createBrowserRouter} from 'react-router-dom'
import Layout from './Layout'
import AddAudio from './Pages/AddAudio'
import AddMood from './Pages/AddMood'
import Dashboard from './Pages/Dashboard'
import Moods from './Pages/Moods'
import Musics from './Pages/Musics'
import Users from './Pages/Uers'

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: '',
                element: <Dashboard />
            },
            {
                path: '/musics',
                element: <Musics />
            },
            {
                path: '/add-audio',
                element: <AddAudio />
            },
            {
                path: '/moods',
                element: <Moods />
            },
            {
                path: '/add-mood',
                element: <AddMood />
            },
            {
                path: '/users',
                element: <Users />
            },
        ]
    }
])

export default router