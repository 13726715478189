import Select from 'react-select'
import { intensityDescOptions, intensityOptions, lengthOptions, tuneOptions } from '../options'
import { fetchMoods } from './Moods'
import { useState, useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate, useNavigation } from 'react-router-dom'
import { path } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '50px', 
      height: '50px',  
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '50px',
      padding: '0 8px', 
    })
}

export default function AddAudio(){
    const [moods, setMoods] = useState([])
    const [loading, setLoading] = useState(null)
    const navigation = useNavigate()
    const location = useLocation()
    const audio = location.state
    const tiRef = useRef()
    const deRef = useRef()
    const tuRef = useRef()
    const leRef = useRef()
    const inRef = useRef()
    const indRef = useRef()
    const moRef = useRef()
    const [defaultMood, setDefaultMood] = useState({})

    useEffect(() => {
        if(audio){
            tiRef.current.value = audio.title
            deRef.current.value = audio.description
            tuRef.current.setValue(tuneOptions.find(t => t.value == audio.tune))
            leRef.current.setValue(lengthOptions.find(l => l.value == audio.length))
            inRef.current.setValue(intensityOptions.find(i => i.value == audio.intensity))
            indRef.current.setValue(intensityDescOptions.find(i => i.value == audio.intensity_desc))
            // setDefaultMood(moods.find(i => i.value == audio.moodId))
        }
        fetchMoods().then(d => {
            let _moods = d.map(i => ({label: i.name, value: i.id}))
            setMoods(_moods)
            if(audio) {
                setDefaultMood(_moods.find(i => i.value == audio.moodId))
            }
        })
    }, [audio])

    const addAudio = e => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('title', e.target.title.value)
        formData.append('description', e.target.description.value)
        formData.append('tune', e.target.tune.value)
        formData.append('length', e.target.length.value)
        formData.append('intensity', e.target.intensity.value)
        formData.append('intensity_desc', e.target.intensity_desc.value)
        formData.append('moodId', e.target.mood.value)
        if(e.target.file.files.length) formData.append('audio', e.target.file.files[0])
        if(audio) formData.append('audioId', audio.id)

        axios[audio ? 'patch' : 'post'](path.audio, formData, {
            headers: {
                'Content-Type': 'multpart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const percentCompleted = Math.round((loaded * 100) / total);
                setLoading(percentCompleted)
              },
        }).then(r => {
            if(r.data.success) {
                toast.success(audio ? 'Audio update' : 'Audio created')
                navigation('/musics')
            }
        })
    }

    return (
        <form method='post' onSubmit={addAudio} className='flex-1 flex flex-col gap-4 p-4'>
            <h2 className="self-center text-[25px] font-bold ">Add Audio</h2>
            <div className='grid  grid-cols-3 gap-4 feel-c1  bg-[#00000010] p-4'>
                <div>
                    <p>Title</p>
                    <input ref={tiRef} className='input' name='title' required/>
                </div>
                <div>
                    <p>Description</p>
                    <input ref={deRef} className='input' name='description' required/> 
                </div>
                <div>
                    <p>Tune</p>
                    <Select options={tuneOptions} styles={customStyles} name='tune' required ref={tuRef}/>
                </div>
                <div>
                    <p>Length</p>
                    <Select options={lengthOptions} styles={customStyles} name='length'required ref={leRef}/>
                </div>
                <div>
                    <p>Mood <Link to='/add-mood' className='text-sm text-[#0000ff]'> | Add Mood</Link></p> 
                    <Select options={moods} styles={customStyles} name='mood' onChange={setDefaultMood} required ref={moRef} value={defaultMood}/>
                </div>
                <div>
                    <p>Intensity</p> 
                    <Select options={intensityOptions} styles={customStyles} name='intensity' required ref={inRef}/>
                </div>
                <div>
                    <p>Intensity desc</p> 
                    <Select options={intensityDescOptions} styles={customStyles} name='intensity_desc'required ref={indRef}/>
                </div>
                <div>
                    <p>Audio File</p>
                    <input className='input' type='file' name='file' required/>
                </div>
            </div>
            <button className='py-2 px-8 font-bold text-[22px] bg-main text-black self-center' type='submit' disabled={loading}>{loading ? `Uploading.. ${loading}%` : audio ? 'Update' : 'Submit'}</button>
        </form>
    )
}