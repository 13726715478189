import { Dashboard, MusicNote, Mood, People } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";

export default function SideBar(){
    const location = useLocation()
    return (
        <div className='p-4 px-0 flex flex-col'>
            <Link to='/' className={'flex gap-2 items-center p-4 px-8 ' + (location.pathname === '/' && 'bg-main') }>
                <Dashboard>Filled</Dashboard>
                <p>Dashboard</p>
            </Link>
            <Link to='/moods' className={'flex gap-2 items-center p-4 px-8 ' + (location.pathname === '/moods' && 'bg-main')}>
                <Mood>Filled</Mood>
                <p>Moods</p>
            </Link>
            <Link to='/musics' className={'flex gap-2 items-center p-4 px-8 ' + (location.pathname === '/musics' && 'bg-main')}>
                <MusicNote>Filled</MusicNote>
                <p>Audios</p>
            </Link>
            <Link to='/users' className={'flex gap-2 items-center p-4 px-8 ' + (location.pathname === '/users' && 'bg-main')}>
                <People>Filled</People>
                <p>App Users</p>
            </Link>
        </div>
    )
}