import logo from '../assets/logo.png'
import { ExitToApp } from "@mui/icons-material";
import { useContext } from 'react';
import { appContext } from '../App';

export default function Header(){
    const {setToken} = useContext(appContext)
    const logout = () => {
        setToken(null)
        localStorage.setItem('feel_token', '')
    }
    
    return (
        <div className='p-2 px-6 bg-white shadow-md flex items-center justify-between'>
            <div className='flex justify-center items-center gap-4'>
                <img src={logo} alt='logo' className='w-[40px] object-contain self-center' />
                <h4 className='uppercase font-bold'>Feel App Admin</h4>
            </div>
            <button onClick={logout} className='bg-none'>
                <ExitToApp>Filled</ExitToApp>
            </button>
        </div>
    )
}
