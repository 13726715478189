import { useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import { intensityDescOptions, intensityOptions, lengthOptions, moodHZRangeOptions, tuneOptions } from '../options'
import axios from 'axios'
import { baseUrl, path } from '../constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '50px', 
      height: '50px',  
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '50px',
      padding: '0 8px', 
    })
}

export default function AddMood(){
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const navigation = useNavigate()
    const location = useLocation()
    const nRef = useRef()
    const dRef = useRef()
    const sRef = useRef()

    const mood = location.state
    const addMood = e => {
        let id = toast.loading('processing...')
        e.preventDefault()
        axios[mood ? 'patch' : 'post'](path.mood, {
            name: e.target.name.value,
            description: e.target.description.value,
            hz_range: e.target.hz_range.value,
            id: mood?.id
        }).then(r => {
            if(r.data.success){
                navigation('/moods')
                toast.dismiss()
                toast.success(mood ? 'mood updated' : 'mood created')
            }
        })
        
        
    }

    useEffect(() => {
        if(mood){
            nRef.current.value = mood.name
            dRef.current.value = mood.description
            let selected = moodHZRangeOptions.find(m => m.value == mood.hz_range)
            sRef.current.setValue(selected);
        }
    }, [mood])

    return (
        <form method="post" onSubmit={addMood} className='flex-1 flex flex-col gap-4 p-4'>
            <h2 className="self-center text-[25px] font-bold ">{mood ? 'Edit Mood' : 'Add Mood'}</h2>
            <div className='grid  grid-cols-3 gap-4 feel-c1 bg-[#00000010] p-4'>
                <div>
                    <p>Mood Name</p>
                    <input name="name" ref={nRef} className='input'  required/>
                </div>
                <div>
                    <p>Description</p>
                    <input name="description" ref={dRef} className='input' required /> 
                </div>
                <div>
                    <p>HZ Range</p>
                    <Select options={moodHZRangeOptions} styles={customStyles} name="hz_range" required ref={sRef} />
                </div>
            </div>
            <button type="submit" className='py-2 px-8 font-bold text-[22px] bg-main text-black self-center' type='submit'>{mood ? 'Update' : 'Submit'}</button>
        </form>
    )
}