import ReactAudioPlayer from 'react-audio-player';
import { Cancel } from "@mui/icons-material";
import { useContext } from 'react';
import { appContext } from '../App';
import { path } from '../constants';
import { toast } from 'react-toastify';

export default function AudioPlayer(){
    const {audioPlay, setAudioPlay} = useContext(appContext)
    if(!audioPlay.visible) return null
    return (
        <div className='dim'>
            <div className='alert-box'>
                <h4>Playing: {audioPlay.title}</h4>
                <ReactAudioPlayer
                    src={path.streamAudio + audioPlay.streamId}
                    autoPlay
                    controls
                    onError={(e) => {
                        console.log(e)
                        toast.error('audio could not be played')
                    } }
                />
                <button onClick={() => setAudioPlay({visible: false})} className='bg-none'>
                    <Cancel>Filled</Cancel>
                </button>
            </div>
        </div>
    )
}